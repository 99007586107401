import ArticleBody from "@/components/ArticleBody";
import { useAdContext } from "@/contexts/AdContext";
// import useSendConversionEventToAlgolia from "@/hooks/useSendConversionEventToAlgolia";
import craft from "@/lib/craft";
import getCommonStaticProps from "@/lib/getCommonStaticProps";
import QueryArticle from "@/queries/QueryArticle.graphql";
import Ad from "@/src/ads/components/Ad";
import AdRosTop from "@/src/ads/components/AdRosTop";
import Entry from "components/Entry";
import EntryContent from "components/EntryContent";
import EntryHeader from "components/EntryHeader";
import FeaturedVideo from "components/FeaturedVideo";
import HeroImage from "components/HeroImage";
import PageLoading from "components/PageLoading";
import { observer } from "mobx-react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { Seomatic } from "react-seomatic";
import createBreadcrumbsJsonLd from "@/src/recipes/lib/createBreadcrumbsJsonLd";

export async function getStaticPaths() {
  return { paths: [], fallback: "blocking" };
}

export async function getStaticProps({ params, previewData, preview }) {
  // Fetch common static properties
  const commonStaticProps = await getCommonStaticProps();

  const queries = [
    {
      document: QueryArticle,
      variables: {
        slug: params.slug,
      },
    },
  ];

  const response = await craft(queries, preview ? previewData.token : null);

  const { article } = response[0];

  if (!article) {
    return {
      props: {
        ...commonStaticProps,
      },
      notFound: true,
    };
  }

  const breadcrumbs = [
    { label: "Articles", href: "/life" },
    { label: article.title, href: null },
  ];

  const jsonLdSchema = [createBreadcrumbsJsonLd(breadcrumbs)];

  return {
    props: {
      ...commonStaticProps,
      article,
      breadcrumbs,
      seomatic: article.seomatic,
      jsonLdSchema,
    },
    revalidate: process.env.VERCEL_ENV !== "production" ? 1 : 3600,
  };
}

const Article = observer(({ article, premiumPromotion }) => {
  const router = useRouter();

  // Enable/disable ads for this article
  const { setShowAds } = useAdContext();
  useEffect(() => setShowAds(!article.hideAds ?? true));

  /*
		Algolia
	*/

  // useSendConversionEventToAlgolia(article); // TODO: no longer sending events to algolia; remove entirely eventually

  // If the page is not yet generated show a loading
  if (router.isFallback) {
    return <PageLoading />;
  }

  if (!article) return null;

  return (
    <>
      <Seomatic Head={Head} {...article.seomatic} />

      {article.magazineArticle && (
        <>
          <ArticleBody
            body={article.body}
            parent={{
              title: "Life",
              url: `/life`,
            }}
          />
        </>
      )}

      {!article.magazineArticle && (
        <>
          <Entry key={article.id}>
            <_EntryHeroImage article={article} />
            <EntryHeader entry={article} />

            <AdRosTop />

            <_EntryFeaturedVideo
              article={article}
              premiumPromotion={premiumPromotion}
            />
          </Entry>

          <Ad
            placementName={
              process.env.NEXT_PUBLIC_ADS_PROVIDER === "freestar"
                ? "mob.co.uk_leaderboard_3"
                : "htlad-ros_postarticle"
            }
            className="py-6 lg:py-12"
          />
        </>
      )}
    </>
  );
});

const _EntryHeroImage = ({ article }) => {
  const heroImage = useRef(
    article.heroImage && article.heroImage.length > 0
      ? article.heroImage[0]
      : null
  );

  if (!heroImage.current) return null;

  return <HeroImage image={heroImage.current} />;
};

const _EntryFeaturedVideo = ({ article, premiumPromotion }) => {
  const featuredVideo = useRef(
    article.featuredVideo && article.featuredVideo.length > 0
      ? article.featuredVideo[0]
      : null
  );

  return (
    <EntryContent entry={article} premiumPromotion={premiumPromotion}>
      {featuredVideo.current && featuredVideo.current.brightcoveVideoId && (
        <FeaturedVideo
          heading={featuredVideo.current.heading}
          brightcoveVideoId={featuredVideo.current.brightcoveVideoId}
          textColor={featuredVideo.current.textColor}
          backgroundColor={featuredVideo.current.backgroundColor}
        />
      )}
    </EntryContent>
  );
};

export default Article;
